var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('center-page', [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('h3', {
    staticClass: "tit"
  }, [_vm._v("자주 묻는 질문")])]), _c('v-tabs', {
    staticClass: "mb-40px mb-lg-60px",
    attrs: {
      "grow": ""
    }
  }, _vm._l(_vm.types, function (faq, index) {
    return _c('tab-primary', {
      key: index,
      attrs: {
        "exact": "",
        "to": {
          path: `/center/faq`,
          query: {
            type: faq.value
          }
        }
      }
    }, [_vm._v(_vm._s(faq.text))]);
  }), 1), _c('faq-list')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }