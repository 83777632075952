<template>
    <center-page>
        <notification-list v-if="!$route.params._notification" />
        <notification-view v-else />
    </center-page>
</template>

<script>
import CenterPage from "@/components/client/templates/center-page.vue";

import NotificationList from "@/components/client/center/notifications/notification-list.vue";
import NotificationView from "@/components/client/center/notifications/notification-view.vue";

export default {
    components: {
        CenterPage,
        NotificationList,
        NotificationView,
    },
};
</script>
