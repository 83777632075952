<template>
    <center-page>
        <div class="tit-wrap">
            <h3 class="tit">자주 묻는 질문</h3>
        </div>
        <v-tabs grow class="mb-40px mb-lg-60px">
            <tab-primary exact v-for="(faq, index) in types" :key="index" :to="{ path: `/center/faq`, query: { type: faq.value } }">{{ faq.text }}</tab-primary>
        </v-tabs>

        <faq-list />
    </center-page>
</template>

<script>
import { FAQ_TYPES } from "@/assets/variables";

import CenterPage from "@/components/client/templates/center-page.vue";

import FaqList from "@/components/client/center/faqs/faq-list.vue";

import TabPrimary from "@/components/client/tab/tab-pirmary.vue";

export default {
    components: {
        CenterPage,
        FaqList,
        TabPrimary,
    },
    data() {
        return {
            FAQ_TYPES,
            types: Object.values(FAQ_TYPES),
        };
    },
    computed: {
        subTitle() {
            return FAQ_TYPES[this.$route.query.type]?.text || "전체보기";
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-slide-group__prev--disabled,
    .v-slide-group__next--disabled {
        display: none !important;
    }
}
</style>