var render = function render(){
  var _vm$faqs;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-divider', {
    staticClass: "grey darken-4",
    staticStyle: {
      "border-width": "2px 0 0"
    }
  }), _c('v-expansion-panels', {
    attrs: {
      "flat": ""
    }
  }, _vm._l(_vm.faqs, function (faq) {
    return _c('v-expansion-panel', {
      key: faq === null || faq === void 0 ? void 0 : faq._id
    }, [_c('v-expansion-panel-header', {
      staticClass: "border-b"
    }, [_c('div', {
      staticClass: "d-flex align-center"
    }, [_c('span', {
      staticClass: "faq-mark faq-mark--q"
    }, [_c('span', {
      staticClass: "txt txt--xl txt--light font-weight-bold"
    }, [_vm._v("Q")])]), _c('span', {
      staticClass: "txt txt--dark font-weight-medium"
    }, [_vm._v(_vm._s(faq.subject))])])]), _c('v-expansion-panel-content', {
      staticClass: "border-b"
    }, [_c('span', {
      staticClass: "faq-mark faq-mark--a"
    }, [_c('span', {
      staticClass: "txt txt--xl txt--light font-weight-bold"
    }, [_vm._v("A")])]), _c('span', {
      staticClass: "txt break-words",
      domProps: {
        "innerHTML": _vm._s(faq.content.replace(/\n/g, '<br>'))
      }
    })])], 1);
  }), 1), _c('v-fade-transition', {
    attrs: {
      "leave-absolute": ""
    }
  }, [_c('v-sheet', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !((_vm$faqs = _vm.faqs) !== null && _vm$faqs !== void 0 && _vm$faqs.length),
      expression: "!faqs?.length"
    }],
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm text-center py-20px py-md-30px"
  }, [_vm._v("등록된 질문이 없습니다")]), _c('v-divider')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }