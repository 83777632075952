<template>
    <div class="wrap wrap--products">
        <!-- 헤더 -->
        <main-header></main-header>

        <!-- 메인 -->
        <main class="main" role="main">
            <div class="container">
                <div class="center-area">
                    <div class="center-ban">
                        <h1 class="center-ban__title">모아고 고객센터</h1>
                        <p class="center-ban__pr">고객님과 함께하는 모아고 고객센터 입니다.</p>
                    </div>
                    <center-navigation />
                    <div class="center-board">
                        <div class="terms-container">
                            <div>
                                이메일무단수집거부
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>

        <!-- 푸터 -->
        <main-footer></main-footer>
    </div>
</template>

<script>
import MainHeader from "@/components/client/inc/main-header.vue";
import MainFooter from "@/components/client/inc/main-footer.vue";

export default {
    components: {
        MainHeader,
        MainFooter,
    },
};
</script>
