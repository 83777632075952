<template>
    <div class="mt-20px mt-40px">
        <div class="pa-20px">
            <v-row align="center" justify="center" class="row--sm">
                <v-col cols="12" sm="auto">
                    <v-row align="center" class="row--xxs">
                        <v-col cols="auto">
                            <!-- <v-select v-bind="{ ...attrs_input }" item-text="text" item-value="type" placeholder="선택" class="w-120px" /> -->
                            <v-select v-model="query['searchKey']" v-bind="{ ...attrs_input }" :items="searchItems" dense class="w-120px" />
                        </v-col>
                        <v-col cols="">
                            <v-text-field v-model="query['searchValue']" v-bind="{ ...attrs_input }" dense placeholder="검색어를 입력하세요" clearable @keydown.enter="setQuery" />
                        </v-col>
                        <v-col cols="12" sm="auto">
                            <v-btn v-bind="{ ...btn_primary }" small @click="setQuery">검색</v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import { attrs_input, btn_primary } from "@/assets/variables";
const initQuery = (query = {}) => {
    return {
        searchKey: query?.searchKey || "all",
        searchValue: query?.searchValue || "",
    };
};
export default {
    data: () => ({
        btn_primary,
        attrs_input,

        searchItems: [
            { text: "전체", value: "all" },
            { text: "제목", value: "subject" },
            { text: "내용", value: "content" },
        ],
        query: initQuery()
    }),
    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        setQuery() {
            let { ...query } = this.query || {};

            delete query.page;

            if(!query?.['searchKey']) delete query['searchKey'];
            if(!query?.['searchValue']) delete query['searchValue'];

            this.$router.push({ query });
        }
    },
    watch: {
        "$route.query"() {
            this.sync();
        }
    }
};
</script>

<style>
</style>