<template>
    <v-list flat no-action class="aside-list">
        <template v-for="item in items">
            <v-list-item v-if="!item.children" :key="item.text" :to="item.to" exact exact-active-class="aside-list--active" :class="{ 'aside-list--active': item.isActive }">
                <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
            <template v-else>
                <v-list-group no-action :value="true" :key="item.text">
                    <v-list-item :key="`${item.text}-title`" :to="item.to" exact class="font-weight-bold" color="primary">
                        <v-list-item-title style="font-size: 16px">{{ item.text }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-for="child in item.children" :key="child.text" :to="child.to" exact-path exact-active-class="aside-list--active" :class="{ 'aside-list--active': child.isActive }">
                        <v-list-item-title>{{ child.text }}</v-list-item-title>
                    </v-list-item>
                </v-list-group>
            </template>
        </template>
    </v-list>
</template>

<script>
export default {
    props: {
        items: { type: Array, default: () => [] },
    },
};
</script>

<style lang="scss" scoped>
.aside-list {
    padding: 0;
    border-top: 3px solid var(--v-primary-base);
    ::v-deep {
        .v-list-item {
            min-height: 28px;
            font-size: 14px !important;
            color: var(--v-grey-base);
            padding: 0 16px !important;
            &__title {
                font-size: inherit;
            }
        }
        > .v-list-item,
        > .v-list-group {
            padding: 10px 0;
            border-bottom: 1px solid var(--border-color);
        }
        > .v-list-item,
        > .v-list-group > .v-list-group__header {
            font-weight: 700;
            color: var(--v-grey-darken4);
        }
        > .v-list-group {
            > .v-list-group__header {
                display: none;
            }
            .aside-list {
                &--active {
                    font-weight: 400 !important;
                    color: var(--v-grey-darken4) !important;
                    text-decoration: underline;
                }
            }
        }
        > .v-list-item {
            min-height: 54px;
        }
    }

    &--active {
        font-weight: 700 !important;
        > .v-list-item__title {
            color: var(--v-primary-base) !important;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .aside-list {
        ::v-deep {
            > .v-list-item,
            > .v-list-group {
                padding: 20px 0;
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
