<template>
    <div>
        <!-- S: 검색 -->
        <!-- <faq-list-search /> -->
        <!-- E: 검색 -->

        <!-- S: 게시판 -->
        <v-divider style="border-width: 2px 0 0" class="grey darken-4" />
        <v-expansion-panels flat>
            <v-expansion-panel v-for="faq in faqs" :key="faq?._id">
                <v-expansion-panel-header class="border-b">
                    <div class="d-flex align-center">
                        <!-- <v-chip small outlined class="flex-shrink-0 mr-8px mr-md-12px">{{ faq.typeText }}</v-chip> -->
                        <span class="faq-mark faq-mark--q">
                            <span class="txt txt--xl txt--light font-weight-bold">Q</span>
                        </span>
                        <span class="txt txt--dark font-weight-medium">{{ faq.subject }}</span>
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="border-b">
                    <span class="faq-mark faq-mark--a">
                        <span class="txt txt--xl txt--light font-weight-bold">A</span>
                    </span>
                    <span class="txt break-words" v-html="faq.content.replace(/\n/g, '<br>')" />
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-fade-transition leave-absolute>
            <v-sheet v-show="!faqs?.length" class="text-center">
                <div class="txt txt--sm text-center py-20px py-md-30px">등록된 질문이 없습니다</div>
                <v-divider />
            </v-sheet>
        </v-fade-transition>
        <!-- E: 게시판 -->

        <!-- S: 페이징 -->
        <!-- <div class="pagination-wrap pagination-wrap--sm">
            <v-pagination :value="page" :length="pageCount" :total-visible="11" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </div> -->
        <!-- E: 페이징 -->
    </div>
</template>

<script>
import api from "@/api";

import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";
// import FaqListSearch from "./faq-list-search.vue";

export default {
    components: {
        TitWrapLine,
        // FaqListSearch,
    },
    data: () => ({
        faqs: [],

        limit: 100,
        summary: { totalCount: 0 },

        loading: false,
    }),
    computed: {
        items() {
            return [...this.faqs];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            await this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, faqs } = await api.v1.center.faqs.gets({
                    headers: { skip, limit },
                    params,
                });

                this.faqs = faqs;
                this.summary = summary;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.faq-mark {
    width: 24px;
    min-width: 24px;
    height: 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 12px;
    background-color: var(--v-grey-lighten3);
}
@media (min-width: 1024px) {
    .faq-mark {
        width: 40px;
        min-width: 40px;
        height: 40px;
        margin-right: 14px;
    }
}

::v-deep {
    .v-expansion-panel-header,
    .v-expansion-panel-content__wrap {
        padding: 16px 12px;
    }
    .v-expansion-panel-content__wrap {
        display: flex;
        align-items: center;
        background: var(--v-grey-lighten5);
    }
    .v-expansion-panel--active {
        .faq-mark {
            background-color: var(--v-primary-base);
        }
    }
    .v-expansion-panel--active + .v-expansion-panel,
    .v-expansion-panel--active:not(:first-child) {
        margin-top: 0;
    }
    .v-expansion-panel-header__icon {
        .v-icon {
            color: var(--v-grey-base) !important;
        }
    }
}
</style>
