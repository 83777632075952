var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-none d-lg-block"
  }, [_c('div', {
    staticClass: "pb-12px"
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("질문유형")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "w-180px",
    attrs: {
      "items": _vm.types,
      "dense": "",
      "placeholder": "선택"
    },
    on: {
      "change": function (value) {
        return _vm.$router.push({
          query: {
            type: value
          }
        });
      }
    },
    model: {
      value: _vm.selectType,
      callback: function ($$v) {
        _vm.selectType = $$v;
      },
      expression: "selectType"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_input), false))], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "w-140px",
    attrs: {
      "to": {
        path: './questions/create'
      }
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("1:1 문의 쓰기")])], 1)], 1)], 1), _c('v-data-table', _vm._b({
    staticClass: "v-data-table--default v-data-table--table-fixed v-data-table--simple-mobile v-data-table--tit v-data-table--cursor-pointer",
    attrs: {
      "dense": "",
      "hide-default-footer": "",
      "disable-filtering": "",
      "disable-sort": "",
      "no-data-text": "작성된 문의사항이 없습니다",
      "mobile-breakpoint": "768"
    },
    on: {
      "click:row": function (any, _ref) {
        var item = _ref.item;
        return _vm.showBoard(item);
      }
    },
    scopedSlots: _vm._u([{
      key: `item.index`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.summary.totalCount - (_vm.page - 1) * _vm.limit - _vm.items.indexOf(item)) + " ")];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [item.reply ? _c('span', {
          staticClass: "secondary--text"
        }, [_vm._v("답변완료")]) : _c('span', [_vm._v("답변대기")])];
      }
    }, {
      key: `item.type`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(_vm.QUESTION_TYPES[item.type].text) + " ")];
      }
    }, {
      key: `item.subject`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "text-left"
        }, [_vm._v(_vm._s(item.subject))])];
      }
    }, {
      key: `item.createdAt`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('span', [_vm._v(_vm._s(item.createdAt.toDate()))])];
      }
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items
  }, false))], 1), _c('div', {
    staticClass: "d-lg-none mx-n12px"
  }, [_c('v-container', {
    staticClass: "pb-12px text-right"
  }, [_c('v-select', {
    staticClass: "border-0 font-size-12 w-100px ml-auto",
    attrs: {
      "items": _vm.types,
      "dense": "",
      "append-icon": "mdi-chevron-down",
      "hide-details": "auto"
    },
    on: {
      "change": function (value) {
        return _vm.$router.push({
          query: {
            type: value
          }
        });
      }
    },
    model: {
      value: _vm.type,
      callback: function ($$v) {
        _vm.type = $$v;
      },
      expression: "type"
    }
  })], 1), _vm._l(_vm.items, function (item, index) {
    return _c('v-card', {
      key: index,
      staticClass: "px-16px py-12px border-b",
      attrs: {
        "to": `./questions/${item._id}`,
        "tile": ""
      }
    }, [_c('div', {
      staticClass: "pb-10px"
    }, [_c('div', {
      staticClass: "txt txt--xs mb-2px"
    }, [_vm._v(_vm._s(_vm.QUESTION_TYPES[item.type].text))]), _c('div', {
      staticClass: "txt txt--dark font-weight-medium"
    }, [_vm._v(" " + _vm._s(item.subject))])]), _c('v-row', {
      staticClass: "row--xxs",
      attrs: {
        "justify": "space-between"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [!item.reply ? _c('v-chip', {
      staticClass: "rounded-sm min-w-80px white--text",
      attrs: {
        "large": "",
        "color": "grey lighten-3"
      }
    }, [_c('span', {
      staticClass: "font-size-12"
    }, [_vm._v("답변대기")])]) : _c('v-chip', {
      staticClass: "rounded-sm min-w-80px white--text",
      attrs: {
        "large": "",
        "color": "secondary"
      }
    }, [_c('span', {
      staticClass: "font-size-12"
    }, [_vm._v("답변완료")])])], 1), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('div', {
      staticClass: "txt txt--xs"
    }, [_vm._v(_vm._s(item.createdAt.toDate()))])])], 1)], 1);
  })], 2), _c('div', {
    staticClass: "pagination-wrap pagination-wrap--sm"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": function (page) {
        _vm.$router.push({
          query: Object.assign({}, _vm.$router.query, {
            page
          })
        });
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1), _c('div', {
    staticClass: "pt-md-60px pt-lg-110px d-none d-lg-block"
  }, [_c('list-search', _vm._b({}, 'list-search', {
    loading: _vm.loading,
    hideTypeFilter: _vm.hideTypeFilter
  }, false))], 1), _c('div', {
    staticClass: "btn-wrap btn-wrap--lg mx-n12px mx-md-0px d-md-none"
  }, [_c('v-row', {
    staticClass: "ma-0px ma-md-n4px",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-0px pa-md-4px",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large w-100 min-w-md-140px",
    attrs: {
      "to": {
        path: './questions/create'
      }
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("1:1 문의 쓰기")])], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }