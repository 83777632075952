var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('center-page', [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h2', {
    staticClass: "tit tit--xxs"
  }, [_vm._v("1:1문의")])]), _vm.accessToken ? _c('div', [_c('board-question-input')], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }