<template>
    <div>
        <!-- S: 게시판(PC) -->
        <div class="d-none d-lg-block">
            <div class="pb-12px">
                <v-row align="center" justify="space-between" class="row--xxs">
                    <v-col cols="auto">
                        <v-row align="center" class="row--xxs">
                            <v-col cols="auto"> <div class="txt txt--sm txt--dark">질문유형</div> </v-col>
                            <v-col cols="auto">
                                <v-select v-model="selectType" :items="types" v-bind="{ ...attrs_input }" dense placeholder="선택" class="w-180px" @change="(value)=> $router.push({query: { type: value }})" />
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- S: 버튼(PC) -->
                    <v-col cols="auto">
                        <v-btn v-bind="{ ...btn_primary }" :to="{path: './questions/create'}" class="w-140px">1:1 문의 쓰기</v-btn>
                    </v-col>
                    <!-- E: 버튼(PC) -->
                </v-row>
            </div>
            <v-data-table v-bind="{ headers, items }" dense hide-default-footer disable-filtering disable-sort @click:row="(any, { item }) => showBoard(item)" no-data-text="작성된 문의사항이 없습니다" mobile-breakpoint="768" class="v-data-table--default v-data-table--table-fixed v-data-table--simple-mobile v-data-table--tit v-data-table--cursor-pointer">
                <template #[`item.index`]="{ item }">
                    {{ summary.totalCount - (page - 1) * limit - items.indexOf(item) }}
                </template>
                <template #[`item.actions`]="{ item }">
                    <span v-if="item.reply" class="secondary--text">답변완료</span>
                    <span v-else>답변대기</span>
                </template>
                <template #[`item.type`]="{ item }">
                    {{ QUESTION_TYPES[item.type].text }}
                </template>
                <template #[`item.subject`]="{ item }">
                    <div class="text-left">{{ item.subject }}</div>
                </template>
                <template #[`item.createdAt`]="{ item }">
                    <span>{{ item.createdAt.toDate() }}</span>
                </template>
            </v-data-table>
        </div>
        <!-- E: 게시판(PC) -->
        <!-- S: 게시판(모바일) -->
        <div class="d-lg-none mx-n12px">
            <v-container class="pb-12px text-right">
                <v-select v-model="type" :items="types" dense append-icon="mdi-chevron-down" hide-details="auto" class="border-0 font-size-12 w-100px ml-auto" @change="(value)=> $router.push({query: { type: value }})" />
            </v-container>
            <!-- 반복 -->
            <v-card v-for="(item, index) in items" :key="index" :to="`./questions/${item._id}`" tile class="px-16px py-12px border-b">
                <div  class="pb-10px">
                    <div class="txt txt--xs mb-2px">{{ QUESTION_TYPES[item.type].text }}</div>
                    <div class="txt txt--dark font-weight-medium"> {{ item.subject }}</div>
                </div>
                <v-row justify="space-between" class="row--xxs">
                    <v-col cols="auto">
                        <v-chip v-if="!item.reply" large color="grey lighten-3" class="rounded-sm min-w-80px white--text"><span class="font-size-12">답변대기</span></v-chip>
                        <v-chip v-else large color="secondary" class="rounded-sm min-w-80px white--text"><span class="font-size-12">답변완료</span></v-chip>
                    </v-col>
                    <v-col cols="auto">
                        <div class="txt txt--xs">{{ item.createdAt.toDate() }}</div>
                    </v-col>
                </v-row>
            </v-card>
            <!-- // 반복 -->
        </div>
        <!-- E: 게시판(모바일) -->

        <!-- S: 페이징 -->
        <div class="pagination-wrap pagination-wrap--sm">
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" @input="(page)=>{ $router.push({ query: {...$router.query, page}})}" />
        </div>
        <!-- E: 페이징 -->

        <!-- S: 검색 -->
        <div class="pt-md-60px pt-lg-110px d-none d-lg-block">
            <list-search v-bind="{ loading, hideTypeFilter }" />
        </div>
        <!-- E: 검색 -->

        <!-- S: 버튼(모바일) -->
        <div class="btn-wrap btn-wrap--lg mx-n12px mx-md-0px d-md-none">
            <v-row justify="center" class="ma-0px ma-md-n4px">
                <v-col cols="12" md="auto" class="pa-0px pa-md-4px">
                    <v-btn v-bind="{ ...btn_primary }" :to="{path: './questions/create'}" class="v-size--xx-large w-100 min-w-md-140px">1:1 문의 쓰기</v-btn>
                </v-col>
            </v-row>
        </div>
        <!-- E: 버튼(모바일) -->
    </div>
</template>

<script>
import api from "@/api";
import { btn_primary, btn_primary3, attrs_input, QUESTION_TYPES } from "@/assets/variables";

import ListSearch from "./list-search.vue";

const headers = [
    { width: "8%", text: "번호", value: "index", align: "center" },
    { width: "12%", text: "상태", value: "actions", align: "center" },
    { width: "16%", text: "문의유형", value: "type", align: "center" },
    { width: "50%", text: "제목", value: "subject", align: "center" },
    { width: "14%", text: "날짜", value: "createdAt", align: "center" },
];

const types = [ { text: "전체보기", value: null }, ...Object.values(QUESTION_TYPES)]

export default {
    components: {
        ListSearch,
    },
    props: {
        hideTypeFilter: { type: Boolean, default: false },
    },
    data: () => ({
        QUESTION_TYPES,
        btn_primary,
        btn_primary3,
        attrs_input,
        types,

        selectType: null,
        questions: [],

        limit: 10,
        summary: { totalCount: 0 },

        headers,

        loading: false,
    }),
    computed: {
        items() {
            return [...this.questions];
        },
        type() {
            return this.$route.query.type;
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            return { ...query };
        },
    },
    mounted() {
        this.init().then(this.search);
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            this.questions = [];
            this.limit = 10;
            this.summary = { totalCount: 0 };
        },
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { skip, limit, params } = this;
                const { summary, questions } = await api.v1.center.questions.gets({ headers: { skip, limit }, params });

                this.summary = summary;
                this.questions = questions;
            } finally {
                this.loading = false;
            }
        },
        showBoard(item) {
            this.$router.push({ path: `./questions/${item._id}`});
        },
    },
};
</script>
