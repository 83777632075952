var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list', {
    staticClass: "aside-list",
    attrs: {
      "flat": "",
      "no-action": ""
    }
  }, [_vm._l(_vm.items, function (item) {
    return [!item.children ? _c('v-list-item', {
      key: item.text,
      class: {
        'aside-list--active': item.isActive
      },
      attrs: {
        "to": item.to,
        "exact": "",
        "exact-active-class": "aside-list--active"
      }
    }, [_c('v-list-item-title', [_vm._v(_vm._s(item.text))])], 1) : [_c('v-list-group', {
      key: item.text,
      attrs: {
        "no-action": "",
        "value": true
      }
    }, [_c('v-list-item', {
      key: `${item.text}-title`,
      staticClass: "font-weight-bold",
      attrs: {
        "to": item.to,
        "exact": "",
        "color": "primary"
      }
    }, [_c('v-list-item-title', {
      staticStyle: {
        "font-size": "16px"
      }
    }, [_vm._v(_vm._s(item.text))])], 1), _vm._l(item.children, function (child) {
      return _c('v-list-item', {
        key: child.text,
        class: {
          'aside-list--active': child.isActive
        },
        attrs: {
          "to": child.to,
          "exact-path": "",
          "exact-active-class": "aside-list--active"
        }
      }, [_c('v-list-item-title', [_vm._v(_vm._s(child.text))])], 1);
    })], 2)]];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }