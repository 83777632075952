var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('center-page', [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h2', {
    staticClass: "tit tit--xxs"
  }, [_vm._v("1:1문의")])]), _vm.accessToken ? _c('div', [_vm.question === undefined ? _c('board-question-list', {
    attrs: {
      "hideTypeFilter": ""
    }
  }) : _vm.question ? _c('board-question-view') : _vm._e()], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }