<template>
    <center-page>
        <div class="tit-wrap tit-wrap--sm">
            <h2 class="tit tit--xxs">1:1문의</h2>
        </div>

        <div v-if="accessToken">
            <board-question-list v-if="question === undefined" hideTypeFilter />
            <board-question-view v-else-if="question" />
        </div>
    </center-page>
</template>

<script>
import CenterPage from "@/components/client/templates/center-page.vue";

import BoardQuestionList from "@/components/client/board/skin/board-question/list.vue";
import BoardQuestionInput from "@/components/client/board/skin/board-question/input.vue";
import BoardQuestionView from "@/components/client/board/skin/board-question/view.vue";

export default {
    components: {
        CenterPage,

        BoardQuestionList,
        BoardQuestionInput,
        BoardQuestionView,
    },
    data: () => ({
    }),
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
        question(){
            return this.$route.params._question;
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init: function () {
            if (!this.accessToken) {
                this.$router.replace("/login");
            }
        },
    },
};
</script>
