<template>
    <v-row v-bind="{ loading }" align="center" justify="center" class="row--sm">
        <v-col cols="12" sm="auto">
            <v-row align="center" class="ma-n4px">
                <!-- <v-col cols="auto" v-if="!hideTypeFilter">
                    <v-select v-model="query.type" :items="types" v-bind="{ loading }" clearable hide-details dense outlined placeholder="전체" class="w-120px" />
                </v-col> -->
                <v-col cols="" class="pa-4px">
                    <v-text-field v-model="query.searchValue" v-bind="{ loading }" placeholder="검색어를 입력하세요" clearable hide-details dense outlined class="w-100 w-sm-300px" @keydown.enter="emit" />
                </v-col>
                <v-col cols="12" sm="auto" class="pa-4px">
                    <v-btn v-bind="{ ...btn_primary, loading }" small class="w-100 w-sm-80px" @click="emit">검색</v-btn>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { QUESTION_TYPES } from "@/assets/variables";
import { btn_primary, btn_primary3 } from "@/assets/variables";

const initQuery = (query = {}) => ({
    ...query,
    type: query.type || null,
    searchKey: query.searchKey || "subject",
    searchValue: query.searchValue || null,
});

export default {
    props: {
        loading: { type: Boolean, default: false },
        hideTypeFilter: { type: Boolean, default: false },
    },
    data: () => ({
        btn_primary,
        btn_primary3,

        query: initQuery(),
    }),
    mounted() {
        this.sync();
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            const { ...query } = this.query || {};

            if (!query?.type) delete query.type;
            if (!query?.searchKey) delete query.searchKey;
            if (!query?.searchValue) delete query.searchValue;

            delete query.page;

            this.$router.push({ query });
        },
    },
};
</script>
