var render = function render(){
  var _vm$question, _vm$question2, _vm$question3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.question ? _c('div', [_c('v-divider', {
    staticClass: "border-dark d-none d-md-block",
    staticStyle: {
      "border-width": "2px 0 0"
    }
  }), _c('div', {
    staticClass: "pb-24px pt-md-24px border-b"
  }, [_c('v-row', {
    attrs: {
      "align": "end",
      "align-md": "start"
    }
  }, [_c('v-col', [_c('div', {
    staticClass: "font-size-18 font-size-md-24 font-weight-medium break-words pb-10px"
  }, [_vm._v(_vm._s(_vm.question.subject))]), _c('v-row', {
    staticClass: "ma-n2px ma-md-n4px",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-2px pa-md-4px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-avatar', {
    attrs: {
      "size": _vm.$vuetify.breakpoint.mdAndUp ? 30 : 24,
      "color": "grey"
    }
  })], 1), _c('v-col', {
    staticClass: "pa-2px pa-md-4px"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_vm._v(_vm._s((_vm$question = _vm.question) === null || _vm$question === void 0 ? void 0 : _vm$question.user.nickname))])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v(_vm._s((_vm$question2 = _vm.question) === null || _vm$question2 === void 0 ? void 0 : _vm$question2.createdAt.toDate()))])])], 1)], 1), _c('div', {
    staticClass: "py-24px"
  }, [_c('div', {
    staticClass: "pb-10px pb-md-20px"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_vm._v("문의유형 : " + _vm._s(_vm.QUESTION_TYPES[_vm.question.type].text))])]), _vm.question.content ? _c('div', {
    staticClass: "min-h-100px min-h-md-150px"
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.question.content.replace(/\n/g, '<br>'))
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "pt-10px"
  }, [_c('v-row', {
    staticClass: "ma-n4px",
    attrs: {
      "align": "center"
    }
  }, _vm._l(_vm.question.files, function (file, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "auto"
      }
    }, [_c('a', {
      staticClass: "d-inline-block",
      attrs: {
        "href": file.src,
        "download": ""
      }
    }, [_c('v-card', {
      staticClass: "attachment-img",
      attrs: {
        "tile": ""
      }
    }, [_c('v-btn', {
      staticClass: "attachment-img__close",
      attrs: {
        "fab": "",
        "color": "primary"
      }
    }, [_c('v-icon', {
      attrs: {
        "x-small": "",
        "color": "white"
      }
    }, [_vm._v("file_download")])], 1), _c('v-img', {
      attrs: {
        "src": file.src,
        "aspect-ratio": 1 / 1,
        "width": "72"
      },
      scopedSlots: _vm._u([{
        key: "placeholder",
        fn: function () {
          return [_c('v-overlay', {
            attrs: {
              "absolute": "",
              "opacity": "0.1"
            }
          }, [_c('v-icon', [_vm._v("mdi-image-broken-variant")])], 1)];
        },
        proxy: true
      }], null, true)
    })], 1)], 1)]);
  }), 1)], 1)]), !_vm.question.reply ? _c('v-divider') : _vm._e(), _vm.question.reply ? [_c('div', {
    staticClass: "mx-n12px mx-md-0"
  }, [_c('v-divider', {
    staticClass: "border-dark d-none d-md-block",
    staticStyle: {
      "border-width": "2px 0 0"
    }
  }), _c('v-divider', {
    staticClass: "d-md-none"
  }), _c('v-divider', {
    staticClass: "grey lighten-5 d-md-none",
    staticStyle: {
      "border-width": "8px 0 0"
    }
  })], 1), _c('div', {
    staticClass: "py-24px"
  }, [_c('div', {
    staticClass: "pb-16px pb-md-24px",
    class: _vm.$vuetify.breakpoint.mdAndUp ? '' : 'border-b'
  }, [_c('v-row', {
    staticClass: "ma-n10px",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-10px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "txt txt--xl txt--dark font-weight-bold"
  }, [_vm._v("답변내역")])]), _c('v-col', {
    staticClass: "pa-10px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("답변일 : " + _vm._s((_vm$question3 = _vm.question) !== null && _vm$question3 !== void 0 && _vm$question3.repliedAt ? _vm.question.repliedAt.toDate() : ""))])])], 1)], 1), _c('div', {
    staticClass: "min-h-100px min-h-md-150px"
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.question.reply.replace(/\n/g, '<br>'))
    }
  })])])] : _vm._e(), _c('div', {
    staticClass: "btn-wrap btn-wrap--lg mx-n12px mx-md-0px"
  }, [_c('v-row', {
    staticClass: "ma-0px ma-md-n4px",
    attrs: {
      "justify": "space-between"
    }
  }, [!_vm.question.reply ? _c('v-col', {
    staticClass: "pa-0px pa-md-4px",
    attrs: {
      "cols": "6",
      "md": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large w-100 min-w-md-140px",
    on: {
      "click": _vm.modify
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("수정")])], 1) : _vm._e(), _c('v-col', {
    staticClass: "pa-0px pa-md-4px",
    attrs: {
      "cols": "6",
      "md": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large min-w-md-140px d-none d-md-block",
    on: {
      "click": _vm.remove
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary), false), [_vm._v("삭제")]), _c('v-btn', _vm._b({
    staticClass: "v-size--xx-large w-100 d-md-none",
    on: {
      "click": _vm.remove
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary3), false), [_vm._v("삭제")])], 1)], 1)], 1)], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }