<template>
    <div>
        <section class="sub-visual d-none d-lg-flex">
            <div class="sub-visual__bg" :style="`background-image: url(${bg})`" />

            <h2 class="tit white--text">{{ tit }}</h2>
        </section>
        <v-container class="container--lg" v-if="$slots['path']">
            <slot name="path" />
        </v-container>
    </div>
</template>

<script>
export default {
    props: {
        tit: { type: String, default: "" },
        bg: { type: String, default: "" },
    },
};
</script>

<style lang="scss" scoped>
.sub-visual {
    position: relative;
    justify-content: center;
    align-items: center;
    &__bg {
        background: center no-repeat;
        background-size: cover;
        width: calc(100% + 2px);
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
    }
}
::v-deep() {
    .v-breadcrumbs {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}
@media (min-width: 1200px) {
    .sub-visual {
        height: 200px;
    }
    ::v-deep() {
        .v-breadcrumbs {
            justify-content: flex-end;
        }
    }
}
</style>
