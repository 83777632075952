var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual-secondary', {
          attrs: {
            "tit": "이동의 편리함, 세상을 만나는 즐거움",
            "bg": "/images/sub/business/about/visual.jpg",
            "mobileBg": "/images/sub/business/about/visual-mo.jpg"
          },
          scopedSlots: _vm._u([{
            key: "mobileBg",
            fn: function () {
              return [_c('div')];
            },
            proxy: true
          }, {
            key: "path",
            fn: function () {
              return [_c('visual-breadcrumbs')];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticClass: "page-section--first",
    attrs: {
      "containerSize": "container--lg"
    }
  }, [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm text-center"
  }, [_c('h3', {
    staticClass: "tit tit--xl"
  }, [_c('span', {
    staticClass: "secondary--text"
  }, [_vm._v("모")]), _vm._v("빌리티 "), _c('span', {
    staticClass: "secondary--text"
  }, [_vm._v("아")]), _vm._v("직도 "), _c('span', {
    staticClass: "secondary--text"
  }, [_vm._v("고")]), _vm._v("민하세요?")])]), _c('p', {
    staticClass: "tit font-weight-regular text-center"
  }, [_vm._v(" 전동 모빌리티가 궁금할 땐?"), _c('br'), _vm._v(" 모아고! ")])]), _c('div', {
    staticClass: "txt txt--lg txt--dark text-center"
  }, [_c('div', [_vm._v("모아고는 이모빌리티 상품을 온라인으로 중개하여 고객에게 편리한 구매 경험을 제공합니다.")]), _c('br'), _c('div', [_vm._v(" 사용자들이 경험을 공유하고 정보를 교류할 수 있는 커뮤니티 플랫폼을 제공하여 "), _c('br'), _vm._v(" 이모빌리티 사용자들 간의 상호작용을 촉진합니다. ")])]), _c('p', {
    staticClass: "txt txt--lg"
  }), _c('v-sheet', {
    staticClass: "highway mt-40px mt-lg-70px"
  }, [_c('v-img', {
    staticClass: "highway__bg d-none d-md-block",
    attrs: {
      "src": "/images/sub/business/about/highway.jpg"
    }
  }), _c('v-img', {
    staticClass: "highway__bg d-md-none",
    attrs: {
      "src": "/images/sub/business/about/highway-mo.jpg"
    }
  }), _c('v-img', {
    staticClass: "highway__logo",
    attrs: {
      "src": "/images/sub/business/about/logo.svg"
    }
  })], 1), _c('v-sheet', {
    staticClass: "aspiration"
  }, [_c('p', {
    staticClass: "txt txt--xl txt--dark font-weight-bold"
  }, [_vm._v(" 우리 모아고는 "), _c('br', {
    staticClass: "d-lg-none"
  }), _vm._v("'"), _c('span', {
    staticClass: "secondary lighten-4"
  }, [_vm._v("지속 가능")]), _vm._v("’한 모빌리티 고객 경험을 ‘"), _c('span', {
    staticClass: "secondary lighten-4"
  }, [_vm._v("최우선")]), _vm._v("’ 시 하며 "), _c('br'), _vm._v(" ‘"), _c('span', {
    staticClass: "secondary lighten-4"
  }, [_vm._v("도전")]), _vm._v("’을 두려워 않는 ‘ "), _c('span', {
    staticClass: "secondary lighten-4"
  }, [_vm._v("혁신")]), _vm._v("’을 통해 "), _c('br', {
    staticClass: "d-none d-sm-block"
  }), _vm._v(" 최고의 스마트 모빌리티 커뮤니티를 제공하는 데 목적을 두고 있습니다. ")])])], 1), _c('page-section', {
    staticClass: "grey lighten-5",
    attrs: {
      "containerSize": "container--lg"
    }
  }, [_c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('h2', {
    staticClass: "tit tit--xl font-secondary"
  }, [_vm._v("B2C")])]), _c('div', {
    staticClass: "b2c"
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "order-md": "2"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "614",
      "src": "/images/sub/business/about/b2c-01.png"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "order-md": "1"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v("#모아고 AS 협력점")])]), _c('p', {
    staticClass: "txt txt--dark line-height-17"
  }, [_vm._v(" 모아고 AS 협력점에서는 전동모빌리티 선택에서 조립까지 원스톱 서비스를 제공하며, "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 긴급한 펑크 상황에서도 근처 AS 협력점을 소개해주고, "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 제품 구매 후에도 튜닝, 정비, 수리 등의 맞춤형 A/S를 지원합니다. ")])])], 1)], 1), _c('div', {
    staticClass: "b2c"
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "630",
      "src": "/images/sub/business/about/b2c-03.png"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v("#추천태그 시스템")])]), _c('p', {
    staticClass: "txt txt--dark line-height-17"
  }, [_vm._v(" 모아고의 추천 태그 시스템을 통해 사용자가 선호하는 스타일의 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 제품에 대한 부품 정보와 튜닝 내역을 확인할 수 있으며, "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 제품 부위에 마우스를 가져가 클릭하면 부품, 옵션, 악세서리 정보를 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 팝업으로 쉽게 조회할 수 있습니다. ")])])], 1)], 1), _c('div', {
    staticClass: "b2c"
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "order-md": "2"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "640",
      "src": "/images/sub/business/about/b2c-04.png"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v("#와글와글 커뮤니티")])]), _c('p', {
    staticClass: "txt txt--dark line-height-17"
  }, [_vm._v(" 모아고의 ‘와글와글 커뮤니티’에서는 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 사용자들의 튜닝 전기자전거, 전동스쿠터, 전동킥보드에 대한 경험, 가이드, 리뷰 등을 확인할 수 있습니다. "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 또한 사용자는 자신의 미니홈을 만들어 팔로워를 모으고, "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 모아고에서 진행하는 이벤트에 참여하며 혜택을 받을 수 있습니다. ")])])], 1)], 1), _c('div', {
    staticClass: "b2c"
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "420",
      "src": "/images/sub/business/about/b2c-05.png"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v("#이벤트")])]), _c('p', {
    staticClass: "txt txt--dark line-height-17"
  }, [_vm._v(" 모아고에서는 매일 다양한 이벤트를 진행하고 있습니다. "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 인스타그램 구독을 통해 이벤트 정보를 쉽게 확인할 수 있으며, "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 전동모빌리티기 후기 이벤트, 베스트 리뷰왕, 스토리 랭킹, 이달의 튜닝왕 등과 같은 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 이벤트와 제품 할인 쿠폰도 제공됩니다. ")])])], 1)], 1), _c('div', {
    staticClass: "b2c"
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "order-md": "2"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "442",
      "src": "/images/sub/business/about/b2c-02.png"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v("#모아고 구독")])]), _c('div', {
    staticClass: "txt txt--dark line-height-17"
  }, [_c('p', [_vm._v(" ‘모아고 구독’ 서비스를 이용하면 안장 높이, 디자인 선택, 실 주행거리, 승차감 등 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 제품 사용 전 궁금한 사항을 해결할 수 있습니다. ")]), _c('p', [_vm._v(" 또한, 곧 오픈할 서비스를 통해 시간과 장소에 구애 받지 않고 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 원하는 이모빌리티를 체험하고, 모아고 대리점의 특별한 커스텀 서비스를 이용할 수 있습니다 ")])])])], 1)], 1)]), _c('page-section', {
    staticClass: "page-section--last",
    attrs: {
      "containerSize": "container--lg"
    }
  }, [_c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('h2', {
    staticClass: "tit tit--xl font-secondary"
  }, [_vm._v("B2B")])]), _c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('h3', {
    staticClass: "tit"
  }, [_vm._v("새로운 이모빌리티 플랫폼 '모아고'")])]), _c('p', {
    staticClass: "txt txt--dark"
  }, [_vm._v(" 이모빌리티의 모든 것을 담아낸 All-in-one 커뮤니티 플랫폼으로"), _c('br'), _vm._v(" 여러분의 비즈니스 모델에 새로운 판매 채널을 확장하고"), _c('br'), _vm._v(" 나아가 귀사의 비전을 달성할 수 있도록 최선을 다하겠습니다. ")])]), _c('div', {
    staticClass: "b2b"
  }, [_c('div', {
    staticClass: "b2b__tit"
  }, [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('h3', {
    staticClass: "tit font-secondary px-56px py-20px primary white--text d-inline-block rounded-pill"
  }, [_vm._v("KEY POINT 1")])]), _c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v("모아고의 다양한 ‘무료’ 입점 혜택")])]), _c('v-row', [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "90",
      "src": "/images/sub/business/about/icon-keypoint-01.png"
    }
  }), _c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v("미니샵")])]), _c('p', {
    staticClass: "txt txt--dark"
  }, [_vm._v(" 내 미니샵의 스토리를"), _c('br'), _vm._v(" 고객과 공유하고"), _c('br'), _vm._v(" Royal Customer를 모집해보세요. ")])], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "90",
      "src": "/images/sub/business/about/icon-keypoint-02.png"
    }
  }), _c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v("최저 수수료")])]), _c('p', {
    staticClass: "txt txt--dark"
  }, [_vm._v(" 이모빌리티 플랫폼 업계 최저의 수수료율로"), _c('br'), _vm._v(" 여러분의 안정적인 비즈니스를"), _c('br'), _vm._v(" 도와드립니다. ")])], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "90",
      "src": "/images/sub/business/about/icon-keypoint-03.png"
    }
  }), _c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v("내주변샵")])]), _c('p', {
    staticClass: "txt txt--dark"
  }, [_vm._v(" 여러분 사업 근처의"), _c('br'), _vm._v(" 잠재 고객과 매칭될 수 있도록"), _c('br'), _vm._v(" 고객 사후관리 서비스를 제공해드립니다. ")])], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "90",
      "src": "/images/sub/business/about/icon-keypoint-04.png"
    }
  }), _c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v("와글와글")])]), _c('p', {
    staticClass: "txt txt--dark"
  }, [_vm._v(" 바야흐로 인플루언서의 시대"), _c('br'), _vm._v(" 당신의 노하우와 정보를 공유하고"), _c('br'), _vm._v(" 당신을 팔로워하는"), _c('br'), _vm._v(" Royal Customer를 모집해보세요. ")])], 1)], 1)], 1), _c('div', {
    staticClass: "b2b"
  }, [_c('div', {
    staticClass: "b2b__tit"
  }, [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('h3', {
    staticClass: "tit font-secondary px-56px py-20px primary white--text d-inline-block rounded-pill"
  }, [_vm._v("KEY POINT 2")])]), _c('div', {
    staticClass: "tit-wrap"
  }, [_c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v("모아고의 ‘비즈니스 패밀리’ 가입 혜택")])]), _c('p', {
    staticClass: "txt txt--dark"
  }, [_vm._v(" 사업에 꼭 필요한 솔루션 서비스들을 모아고에서 제공합니다."), _c('br'), _vm._v(" 급변하는 시장 변화에 대처하기 위한 트렌드 분석"), _c('br'), _vm._v(" 연령, 성별 등 세그먼트에 따른 심층 고객 선호도 조사"), _c('br'), _vm._v(" SEO(검색최적화)를 위한 고객이 주로 찾는 키워드 분석까지! ")])]), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "auto"
    }
  }, [_c('v-card', {
    staticClass: "rounded-pill mx-auto",
    attrs: {
      "width": "228",
      "outlined": "",
      "color": "primary"
    }
  }, [_c('v-responsive', {
    staticClass: "d-flex text-center align-center",
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("유저분석")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "auto"
    }
  }, [_c('v-card', {
    staticClass: "rounded-pill mx-auto",
    attrs: {
      "width": "228",
      "outlined": "",
      "color": "primary"
    }
  }, [_c('v-responsive', {
    staticClass: "d-flex text-center align-center",
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("판매통계")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "auto"
    }
  }, [_c('v-card', {
    staticClass: "rounded-pill mx-auto",
    attrs: {
      "width": "228",
      "outlined": "",
      "color": "primary"
    }
  }, [_c('v-responsive', {
    staticClass: "d-flex text-center align-center",
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("시장현황")])])], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "b2b"
  }, [_c('div', {
    staticClass: "b2b__tit"
  }, [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('h3', {
    staticClass: "tit font-secondary px-56px py-20px primary white--text d-inline-block rounded-pill"
  }, [_vm._v("KEY POINT 3")])]), _c('div', {
    staticClass: "tit-wrap"
  }, [_c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v("모아고의 광고 서비스")])]), _c('p', {
    staticClass: "txt txt--dark"
  }, [_vm._v(" 브랜드 홍보, 신제품 출시, 신규 오픈 등"), _c('br'), _vm._v(" 여러분의 비즈니스에 필요한 모든 광고 효과를"), _c('br'), _vm._v(" 합리적인 가격으로 모아고에서 받아보세요. ")])]), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "auto"
    }
  }, [_c('v-card', {
    staticClass: "rounded-pill mx-auto",
    attrs: {
      "width": "228",
      "outlined": "",
      "color": "primary"
    }
  }, [_c('v-responsive', {
    staticClass: "d-flex text-center align-center",
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("상단배너")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "auto"
    }
  }, [_c('v-card', {
    staticClass: "rounded-pill mx-auto",
    attrs: {
      "width": "228",
      "outlined": "",
      "color": "primary"
    }
  }, [_c('v-responsive', {
    staticClass: "d-flex text-center align-center",
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("하단배너")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "auto"
    }
  }, [_c('v-card', {
    staticClass: "rounded-pill mx-auto",
    attrs: {
      "width": "228",
      "outlined": "",
      "color": "primary"
    }
  }, [_c('v-responsive', {
    staticClass: "d-flex text-center align-center",
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("랜딩페이지 유입")])])], 1)], 1)], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }