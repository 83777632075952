var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('section', {
    staticClass: "sub-visual d-none d-lg-flex"
  }, [_c('div', {
    staticClass: "sub-visual__bg",
    style: `background-image: url(${_vm.bg})`
  }), _c('h2', {
    staticClass: "tit white--text"
  }, [_vm._v(_vm._s(_vm.tit))])]), _vm.$slots['path'] ? _c('v-container', {
    staticClass: "container--lg"
  }, [_vm._t("path")], 2) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }