<template>
    <v-card tag="aside" rounded="" elevation="4" width="100%" class="overflow-hidden">
        <aside-navigation />
    </v-card>
</template>

<script>
import AsideNavigation from "@/components/client/dumb/aside-navigation.vue";

export default {
    components: {
        AsideNavigation,
    },
};
</script>

<style></style>
