<template>
    <v-card tag="aside" width="100%" class="aside">
        <!-- <aside-user-card /> -->
        <h2 class="tit tit--xs">마이페이지</h2>
        <aside-navigation v-if="navigation || $vuetify.breakpoint.mdAndUp" v-bind="{ items }" />
        <v-layout v-if="!$vuetify.breakpoint.mdAndUp" justify-center>
            <v-btn icon large outlined color="grey lighten-3" class="aside-btn" @click.stop="navigation = !navigation">
                <v-icon v-if="navigation">mdi-chevron-up</v-icon>
                <v-icon v-else>mdi-chevron-down</v-icon>
            </v-btn>
        </v-layout>
    </v-card>
</template>

<script>
import AsideNavigation from "@/components/client/dumb/aside-navigation.vue";

export default {
    components: {
        AsideNavigation,
    },
    data: () => ({
        navigation: false,
    }),
    computed: {
        items() {
            return [
                {
                    text: "나의 쇼핑",
                    to: { path: "/mypage/dashboard" },

                    children: [
                        {
                            text: "주문/배송",
                            to: { path: "/mypage/orders" },
                        },
                        {
                            text: "반품/교환",
                            // to: { path: "/mypage/orders?code=claimProcessGoing" },
                            to: { path: "/mypage/orders/return" },
                        },
                        {
                            text: "취소/환불",
                            to: { path: "/mypage/orders/cancel" },
                        },
                        {
                            text: "관심상품",
                            to: { path: "/mypage/likes" },
                        },
                        {
                            text: "최근 본 상품",
                            to: { path: "/mypage/recent" },
                        },
                        {
                            text: "배송 주소록",
                            to: { path: "/mypage/destinations" },
                        },
                        // {
                        //     text: "세금계산서",
                        //     to: { path: "/mypage/tax-bill" },
                        // },
                        {
                            text: "개인결제",
                            to: { path: "/mypage/personal-payment" },
                        },
                        // {
                        //     text: "장바구니",
                        //     to: { path: "/mypage/cart" },
                        // },
                        // {
                        //     text: "영수증 신청/발급",
                        //     to: { path: "/mypage/receipt" },
                        // },
                    ],
                },
                {
                    text: "나의 혜택",
                    children: [
                        {
                            text: "마일리지",
                            to: { path: "/mypage/points" },
                        },
                        {
                            text: "쿠폰",
                            to: { path: "/mypage/coupons" },
                        },
                    ],
                },
                {
                    text: "나의 활동",
                    children: [
                        {
                            text: "1:1문의",
                            to: { path: "/mypage/questions" },
                        },
                        {
                            text: "나의 상품문의",
                            to: { path: "/mypage/inquiries" },
                        },
                        {
                            text: "나의 상품후기",
                            to: { path: "/mypage/reviews" },
                        },
                        {
                            text: "나의 단골 AS샵",
                            to: { path: "/mypage/regular" },
                        },
                    ],
                },
                {
                    text: "나의 정보",
                    children: [
                        {
                            text: "회원정보 수정",
                            to: { path: "/mypage/myinfo" },
                        },
                        {
                            text: "회원 탈퇴",
                            to: { path: "/mypage/leave" },
                        },
                        // {
                        //     text: "환불계좌 관리",
                        //     to: { path: "/mypage/refund-account" },
                        // },
                    ],
                },
                // {
                //     text: "대량구매요청",
                //     to: { path: "/mypage/bulk-purchase" },
                //     isActive: this.$route.path.includes("/mypage/bulk-purchase"),
                // },
            ];
        },
    },
};
</script>

<style lang="scss" scoped>
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .aside {
        transform: translateY(-40px);
        > .tit {
            margin-bottom: 12px;
        }
    }
}

.aside-btn {
    --btn-outline-border-width: 1px;
    &.v-btn.v-btn--outlined.v-btn--icon.v-size--large {
        border-top: 0;
        border-radius: 0 0 6px 6px;
        height: calc(var(--btn-height-lg) / 2) !important;
        .v-icon {
            color: var(--v-grey-base);
        }
    }
}
</style>
