<template>
    <client-page>
        <template #subHead>
            <sub-visual-secondary tit="이동의 편리함, 세상을 만나는 즐거움" bg="/images/sub/business/about/visual.jpg" mobileBg="/images/sub/business/about/visual-mo.jpg">
                <!-- mobileBg slot -->
                <template #mobileBg>
                    <div/>
                </template>
                <template #path>
                    <visual-breadcrumbs />
                </template>
            </sub-visual-secondary>
        </template>

        <page-section class="page-section--first" containerSize="container--lg">
            <div class="tit-wrap">
                <div class="tit-wrap tit-wrap--sm text-center">
                    <h3 class="tit tit--xl"><span class="secondary--text">모</span>빌리티 <span class="secondary--text">아</span>직도 <span class="secondary--text">고</span>민하세요?</h3>
                </div>
                <p class="tit font-weight-regular text-center">
                    전동 모빌리티가 궁금할 땐?<br />
                    모아고!
                </p>
            </div>

            <div class="txt txt--lg txt--dark text-center">
                <div>모아고는 이모빌리티 상품을 온라인으로 중개하여 고객에게 편리한 구매 경험을 제공합니다.</div>
                <br />
                <div>
                    사용자들이 경험을 공유하고 정보를 교류할 수 있는 커뮤니티 플랫폼을 제공하여 <br />
                    이모빌리티 사용자들 간의 상호작용을 촉진합니다.
                </div>
            </div>
            <p class="txt txt--lg"></p>
            <!-- <v-row no-gutters>
                <v-col cols="12" lg="">
                    <v-sheet class="py-40px py-md-80px py-lg-100px">
                        <div class="tit-wrap">
                            <v-img max-width="388" class="ma-auto" src="/images/sub/business/about/once-again.png" />
                        </div>
                        <p class="txt txt--dark text-center">
                            ‘또 다시’ 라는 사전적 의미를 지니고 있으며, <br />
                            ‘도전’ 혹은 ‘재도전’, ‘반복’, ‘실패를 두려워 않음’을<br />
                            상징합니다.
                        </p>
                    </v-sheet>
                </v-col>
                <v-col cols="auto" class="d-none d-lg-block"><v-divider vertical dark /></v-col>
                <v-col cols="12" lg="">
                    <v-sheet class="py-40px py-md-80px py-lg-100px">
                        <div class="tit-wrap">
                            <v-img max-width="358" class="ma-auto" src="/images/sub/business/about/on-the-rail.png" />
                        </div>
                        <p class="txt txt--dark text-center">
                            Rail은 철도 차량이나 전차 등의 모빌리티가 달릴 수 있게 깔아 놓은<br />
                            궤도상의 구조물을 의미하며, <br />
                            여기서 On the Rail은 모빌리티가 궤도 위에 온전히 있는 상태 <br />
                            ‘안내’, ‘지속 가능’, ‘안정’ 혹은 ‘빠르게 나아가는 중’, ‘신속’ 을 상징합니다.
                        </p>
                    </v-sheet>
                </v-col>
            </v-row> -->

            <v-sheet class="highway mt-40px mt-lg-70px">
                <v-img class="highway__bg d-none d-md-block" src="/images/sub/business/about/highway.jpg" />
                <v-img class="highway__bg d-md-none" src="/images/sub/business/about/highway-mo.jpg" />
                <v-img class="highway__logo" src="/images/sub/business/about/logo.svg" />
            </v-sheet>
            <v-sheet class="aspiration">
                <p class="txt txt--xl txt--dark font-weight-bold">
                    우리 모아고는 <br class="d-lg-none" />'<span class="secondary lighten-4">지속 가능</span>’한 모빌리티 고객 경험을 ‘<span class="secondary lighten-4">최우선</span>’ 시 하며 <br />
                    ‘<span class="secondary lighten-4">도전</span>’을 두려워 않는 ‘ <span class="secondary lighten-4">혁신</span>’을 통해 <br class="d-none d-sm-block" />
                    최고의 스마트 모빌리티 커뮤니티를 제공하는 데 목적을 두고 있습니다.
                </p>
            </v-sheet>
        </page-section>

        <page-section class="grey lighten-5" containerSize="container--lg">
            <div class="tit-wrap text-center">
                <h2 class="tit tit--xl font-secondary">B2C</h2>
            </div>

            <div class="b2c">
                <v-row class="row--xxs" align="center">
                    <v-col cols="12" md="6" order-md="2">
                        <v-img max-width="614" class="mx-auto" src="/images/sub/business/about/b2c-01.png" />
                    </v-col>
                    <v-col cols="12" md="6" order-md="1">
                        <div class="tit-wrap tit-wrap--sm">
                            <h3 class="tit tit--xs">#모아고 AS 협력점</h3>
                        </div>
                        <p class="txt txt--dark line-height-17">
                            모아고 AS 협력점에서는 전동모빌리티 선택에서 조립까지 원스톱 서비스를 제공하며, <br class="d-none d-xl-block" />
                            긴급한 펑크 상황에서도 근처 AS 협력점을 소개해주고, <br class="d-none d-xl-block" />
                            제품 구매 후에도 튜닝, 정비, 수리 등의 맞춤형 A/S를 지원합니다.
                        </p>
                    </v-col>
                </v-row>
            </div>

            <div class="b2c">
                <v-row class="row--xxs" align="center">
                    <v-col cols="12" md="6">
                        <v-img max-width="630" class="mx-auto" src="/images/sub/business/about/b2c-03.png" />
                    </v-col>
                    <v-col cols="12" md="6">
                        <div class="tit-wrap tit-wrap--sm">
                            <h3 class="tit tit--xs">#추천태그 시스템</h3>
                        </div>
                        <p class="txt txt--dark line-height-17">
                            모아고의 추천 태그 시스템을 통해 사용자가 선호하는 스타일의 <br class="d-none d-xl-block" />
                            제품에 대한 부품 정보와 튜닝 내역을 확인할 수 있으며, <br class="d-none d-xl-block" />
                            제품 부위에 마우스를 가져가 클릭하면 부품, 옵션, 악세서리 정보를 <br class="d-none d-xl-block" />
                            팝업으로 쉽게 조회할 수 있습니다.
                        </p>
                    </v-col>
                </v-row>
            </div>

            <div class="b2c">
                <v-row class="row--xxs" align="center">
                    <v-col cols="12" md="6" order-md="2">
                        <v-img max-width="640" class="mx-auto" src="/images/sub/business/about/b2c-04.png" />
                    </v-col>
                    <v-col cols="12" md="6">
                        <div class="tit-wrap tit-wrap--sm">
                            <h3 class="tit tit--xs">#와글와글 커뮤니티</h3>
                        </div>
                        <p class="txt txt--dark line-height-17">
                            모아고의 ‘와글와글 커뮤니티’에서는 <br class="d-none d-xl-block" />
                            사용자들의 튜닝 전기자전거, 전동스쿠터, 전동킥보드에 대한 경험, 가이드, 리뷰 등을 확인할 수 있습니다. <br class="d-none d-xl-block" />
                            또한 사용자는 자신의 미니홈을 만들어 팔로워를 모으고, <br class="d-none d-xl-block" />
                            모아고에서 진행하는 이벤트에 참여하며 혜택을 받을 수 있습니다.
                        </p>
                    </v-col>
                </v-row>
            </div>

            <div class="b2c">
                <v-row class="row--xxs" align="center">
                    <v-col cols="12" md="6">
                        <v-img max-width="420" class="mx-auto" src="/images/sub/business/about/b2c-05.png" />
                    </v-col>
                    <v-col cols="12" md="6">
                        <div class="tit-wrap tit-wrap--sm">
                            <h3 class="tit tit--xs">#이벤트</h3>
                        </div>
                        <p class="txt txt--dark line-height-17">
                            모아고에서는 매일 다양한 이벤트를 진행하고 있습니다. <br class="d-none d-xl-block" />
                            인스타그램 구독을 통해 이벤트 정보를 쉽게 확인할 수 있으며, <br class="d-none d-xl-block" />
                            전동모빌리티기 후기 이벤트, 베스트 리뷰왕, 스토리 랭킹, 이달의 튜닝왕 등과 같은 <br class="d-none d-xl-block" />
                            이벤트와 제품 할인 쿠폰도 제공됩니다.
                        </p>
                    </v-col>
                </v-row>
            </div>

            <div class="b2c">
                <v-row class="row--xxs" align="center">
                    <v-col cols="12" md="6" order-md="2">
                        <v-img max-width="442" class="mx-auto" src="/images/sub/business/about/b2c-02.png" />
                    </v-col>
                    <v-col cols="12" md="6">
                        <div class="tit-wrap tit-wrap--sm">
                            <h3 class="tit tit--xs">#모아고 구독</h3>
                        </div>
                        <div class="txt txt--dark line-height-17">
                            <p>
                                ‘모아고 구독’ 서비스를 이용하면 안장 높이, 디자인 선택, 실 주행거리, 승차감 등 <br class="d-none d-xl-block" />
                                제품 사용 전 궁금한 사항을 해결할 수 있습니다.
                            </p>
                            <p>
                                또한, 곧 오픈할 서비스를 통해 시간과 장소에 구애 받지 않고 <br class="d-none d-xl-block" />
                                원하는 이모빌리티를 체험하고, 모아고 대리점의 특별한 커스텀 서비스를 이용할 수 있습니다
                            </p>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </page-section>

        <page-section class="page-section--last" containerSize="container--lg">
            <div class="tit-wrap text-center">
                <h2 class="tit tit--xl font-secondary">B2B</h2>
            </div>

            <div class="text-center">
                <div class="tit-wrap">
                    <h3 class="tit">새로운 이모빌리티 플랫폼 '모아고'</h3>
                </div>
                <p class="txt txt--dark">
                    이모빌리티의 모든 것을 담아낸 All-in-one 커뮤니티 플랫폼으로<br />
                    여러분의 비즈니스 모델에 새로운 판매 채널을 확장하고<br />
                    나아가 귀사의 비전을 달성할 수 있도록 최선을 다하겠습니다.
                </p>
            </div>

            <div class="b2b">
                <div class="b2b__tit">
                    <div class="tit-wrap">
                        <h3 class="tit font-secondary px-56px py-20px primary white--text d-inline-block rounded-pill">KEY POINT 1</h3>
                    </div>
                    <h3 class="tit tit--xs">모아고의 다양한 ‘무료’ 입점 혜택</h3>
                </div>

                <v-row>
                    <v-col cols="12" md="6" lg="3" class="text-center">
                        <v-img max-width="90" class="mx-auto" src="/images/sub/business/about/icon-keypoint-01.png" />
                        <div class="tit-wrap tit-wrap--sm">
                            <h3 class="tit tit--xs">미니샵</h3>
                        </div>
                        <p class="txt txt--dark">
                            내 미니샵의 스토리를<br />
                            고객과 공유하고<br />
                            Royal Customer를 모집해보세요.
                        </p>
                    </v-col>
                    <v-col cols="12" md="6" lg="3" class="text-center">
                        <v-img max-width="90" class="mx-auto" src="/images/sub/business/about/icon-keypoint-02.png" />
                        <div class="tit-wrap tit-wrap--sm">
                            <h3 class="tit tit--xs">최저 수수료</h3>
                        </div>
                        <p class="txt txt--dark">
                            이모빌리티 플랫폼 업계 최저의 수수료율로<br />
                            여러분의 안정적인 비즈니스를<br />
                            도와드립니다.
                        </p>
                    </v-col>
                    <v-col cols="12" md="6" lg="3" class="text-center">
                        <v-img max-width="90" class="mx-auto" src="/images/sub/business/about/icon-keypoint-03.png" />
                        <div class="tit-wrap tit-wrap--sm">
                            <h3 class="tit tit--xs">내주변샵</h3>
                        </div>
                        <p class="txt txt--dark">
                            여러분 사업 근처의<br />
                            잠재 고객과 매칭될 수 있도록<br />
                            고객 사후관리 서비스를 제공해드립니다.
                        </p>
                    </v-col>
                    <v-col cols="12" md="6" lg="3" class="text-center">
                        <v-img max-width="90" class="mx-auto" src="/images/sub/business/about/icon-keypoint-04.png" />
                        <div class="tit-wrap tit-wrap--sm">
                            <h3 class="tit tit--xs">와글와글</h3>
                        </div>
                        <p class="txt txt--dark">
                            바야흐로 인플루언서의 시대<br />
                            당신의 노하우와 정보를 공유하고<br />
                            당신을 팔로워하는<br />
                            Royal Customer를 모집해보세요.
                        </p>
                    </v-col>
                </v-row>
            </div>

            <div class="b2b">
                <div class="b2b__tit">
                    <div class="tit-wrap">
                        <h3 class="tit font-secondary px-56px py-20px primary white--text d-inline-block rounded-pill">KEY POINT 2</h3>
                    </div>
                    <div class="tit-wrap">
                        <h3 class="tit tit--xs">모아고의 ‘비즈니스 패밀리’ 가입 혜택</h3>
                    </div>
                    <p class="txt txt--dark">
                        사업에 꼭 필요한 솔루션 서비스들을 모아고에서 제공합니다.<br />
                        급변하는 시장 변화에 대처하기 위한 트렌드 분석<br />
                        연령, 성별 등 세그먼트에 따른 심층 고객 선호도 조사<br />
                        SEO(검색최적화)를 위한 고객이 주로 찾는 키워드 분석까지!
                    </p>
                </div>
                <v-row justify="center">
                    <v-col cols="6" md="auto">
                        <v-card width="228" outlined class="rounded-pill mx-auto" color="primary">
                            <v-responsive :aspect-ratio="1 / 1" class="d-flex text-center align-center">
                                <h4 class="tit tit--xs">유저분석</h4>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" md="auto">
                        <v-card width="228" outlined class="rounded-pill mx-auto" color="primary">
                            <v-responsive :aspect-ratio="1 / 1" class="d-flex text-center align-center">
                                <h4 class="tit tit--xs">판매통계</h4>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" md="auto">
                        <v-card width="228" outlined class="rounded-pill mx-auto" color="primary">
                            <v-responsive :aspect-ratio="1 / 1" class="d-flex text-center align-center">
                                <h4 class="tit tit--xs">시장현황</h4>
                            </v-responsive>
                        </v-card>
                    </v-col>
                </v-row>
            </div>

            <div class="b2b">
                <div class="b2b__tit">
                    <div class="tit-wrap">
                        <h3 class="tit font-secondary px-56px py-20px primary white--text d-inline-block rounded-pill">KEY POINT 3</h3>
                    </div>
                    <div class="tit-wrap">
                        <h3 class="tit tit--xs">모아고의 광고 서비스</h3>
                    </div>
                    <p class="txt txt--dark">
                        브랜드 홍보, 신제품 출시, 신규 오픈 등<br />
                        여러분의 비즈니스에 필요한 모든 광고 효과를<br />
                        합리적인 가격으로 모아고에서 받아보세요.
                    </p>
                </div>
                <v-row justify="center">
                    <v-col cols="6" md="auto">
                        <v-card width="228" outlined class="rounded-pill mx-auto" color="primary">
                            <v-responsive :aspect-ratio="1 / 1" class="d-flex text-center align-center">
                                <h4 class="tit tit--xs">상단배너</h4>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" md="auto">
                        <v-card width="228" outlined class="rounded-pill mx-auto" color="primary">
                            <v-responsive :aspect-ratio="1 / 1" class="d-flex text-center align-center">
                                <h4 class="tit tit--xs">하단배너</h4>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" md="auto">
                        <v-card width="228" outlined class="rounded-pill mx-auto" color="primary">
                            <v-responsive :aspect-ratio="1 / 1" class="d-flex text-center align-center">
                                <h4 class="tit tit--xs">랜딩페이지 유입</h4>
                            </v-responsive>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </page-section>
    </client-page>
</template>

<script>
import { DISPLAY_CODES, EXHIBITION_GROUPS } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import PopupLayer from "@/components/client/popup/popup-layer.vue";

import PageSection from "@/components/client/templates/page-section.vue";
import PageSectionWide from "@/components/client/templates/page-section-wide.vue";
import SubVisualSecondary from "@/components/client/sub/sub-visual-secondary.vue";
import VisualBreadcrumbs from '@/components/client/inc/visual-breadcrumbs.vue';

const groups = Object.values(EXHIBITION_GROUPS);

export default {
    components: {
        ClientPage,
        PopupLayer,

        PageSection,
        PageSectionWide,
        SubVisualSecondary,
        VisualBreadcrumbs,
    },
    data: () => ({
        popups: [],

        groups,
        DISPLAY_CODES,
    }),
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                await this.getPopups();
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },

        getPopups() {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.get("/api/v1/popups");
                    this.popups = res.data.popups;
                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },
    },
    computed: {
        breadcrumbsItems() {
            const items = [
                { text: "HOME", to: "/" },
                { text: "Business", to: "/sub/business/about" },
                { text: "회사소개", to: "/sub/business/about" },
            ];
            if (this.activeItem) {
                items.push({
                    text: this.activeItem?.title,
                    to: this.activeItem?.to,
                });
            }
            return items.map((item) => ({ ...item, disabled: false }));
        },
    },
};
</script>

<style lang="scss" scoped>
.highway {
    aspect-ratio: 720/362;
    position: relative;
    padding-top: 24px;
    &__bg {
        background-size: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    &__logo {
        max-width: 240px;
        margin: auto;
    }
}
.aspiration {
    width: calc(100% - var(--container-gutter) * 2);
    max-width: 1076px;
    padding: 30px 20px;
    margin: auto;
    text-align: center;
    transform: translateY(-100px);
}
@media (min-width: 576px) {
    .aspiration {
        transform: translateY(-90%);
    }
}
@media (min-width: 768px) {
    .highway {
        aspect-ratio: initial;
        height: 290px;
        padding-top: 50px;
        &__logo {
            max-width: 270px;
        }
    }
}
@media (min-width: 1200px) {
    .aspiration {
        padding: 60px 0;
    }
    .aspiration {
        transform: translateY(-50%);
    }
}

.b2c {
    margin-bottom: 60px;
    &:last-child {
        border: none;
        padding-bottom: 0;
        margin-bottom: 0;
    }
    &__list {
        > li {
            margin-bottom: 24px;
            &:last-child {
                margin-bottom: 0;
            }

            padding-left: 34px;
            position: relative;
            &::before {
                content: "";
                width: 24px;
                height: 24px;
                background: url(/images/sub/business/about/check-circle.png) no-repeat center;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
    .v-image {
        margin-bottom: 28px;
    }
}
@media (min-width: 768px) {
    .b2c {
        padding-bottom: 60px;
        border-bottom: 1px solid var(--border-color);
        .v-image {
            margin-bottom: 0;
        }
    }
}

.b2b {
    margin-top: 40px;
    &__tit {
        text-align: center;
        margin-bottom: 30px;
    }
}
@media (min-width: 1024px) {
    .b2b {
        margin-top: 90px;
        &__tit {
            margin-bottom: 70px;
        }
    }
}
</style>
