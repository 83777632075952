<template>
    <div class="tit-wrap--line">
        <div class="pb-12px pb-md-20px">
            <v-row align="center" class="row--sm">
                <v-col col="auto" v-if="this.$slots['titleContents'] || this.$slots['title'] || title">
                    <div v-if="this.$slots['titleContents']">
                        <slot name="titleContents" />
                    </div>
                    <h3 v-else class="tit" :class="titleClass">{{ title }}</h3>
                </v-col>
                <v-spacer v-if="this.$slots['titleContents'] || this.$slots['title'] || title" />
                <v-col v-if="this.$slots['default']" :col="this.$slots['titleContents'] || this.$slots['title'] || title ? 'auto' : ''">
                    <slot />
                </v-col>
            </v-row>
        </div>
        <v-divider v-if="!noDivider" style="border-width: 2px 0 0" class="grey darken-4" />
    </div>
</template>

<script>
export default {
    props: {
        title: { type: String },
        titleClass: { type: String, default: "tit--xs" },
        noDivider: { type: Boolean },
    },
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
